<template>
<!-- 支付 不显示 -->
  <div class="content-wrap">
    <div class="bold-title">支付认证</div>
    <div v-if="1">
      <p>店铺已开通，请完成支付宝认证</p>
      <p>认证金额:0.1元</p>
      <p><el-image src="https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png"></el-image></p>
      <p><el-button @click="$router.push({path: 'storeInfo'})">已支付</el-button></p>
    </div>
    <div v-if="1">
      <p class="pay">请缴纳押金</p>
      <p>保证金金额;1000</p>
      <p>增值服务费</p>
      <p>合计支付:1000元</p>
      <p>请选择支付方式</p>
      <p><el-image src="https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png"></el-image></p>
      <el-checkbox v-model="formInfo.check"></el-checkbox>我已阅读并同意遵守《菁星校园CEO大赛用户协议》
      <p><el-button @click="$router.push({path: 'storeInfo'})">已支付</el-button></p>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
export default {
  name: "storeInfo",
  data() {
    return {
      detail_info: {},
      formInfo: {
        check: false
      },
      Editor: false,
    };
  },
  created() {
  },
  methods: {
    self_edit() {this.Editor=true},
    save_info() {this.Editor=false},
    cancel_info() {this.Editor=false},
    goPay() {},
  }
};
</script>
<style scoped lang="scss">
.bold-title{ width: 100%; border-bottom: 1px solid #999; font-size: 20px; font-weight: 600; }
.pay { font-size: 25px; font-weight: 600px; }
</style>
